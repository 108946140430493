import snackbarPlugin from '@/plugins/snackbar'
import store from '@/store'
import { exportData } from '@/utils'
import {
  mdiAccountOutline,
  mdiAccountTie,
  mdiAccountTieOutline,
  mdiDnsOutline,
  mdiDomain,
  mdiSchoolOutline,
} from '@mdi/js'
import { ObjectID } from 'bson'
import { ref, watch } from 'vue'

export default function useUsersList() {
  const userListTable = ref([])
  const tableColumns = [
    { text: '', value: 'avatar' },
    { text: 'Nombre', value: 'name' },
    { text: 'Apellido', value: 'lastname' },
    { text: 'Documento', value: 'document' },
    { text: 'Telefono', value: 'phone' },
    { text: 'Email', value: 'email' },
    { text: 'Rol', value: 'role' },
    {
      text: 'Acciones',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const roleFilter = ref(null)
  const totalUserListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['name'],
    sortDesc: [false],
  })
  const userTotalLocal = ref([])
  const selectedRows = ref([])
  const timer = ref('')

  // fetch data
  const fetchUsers = () => {
    store
      .dispatch('user/fetchUsers', {
        q: searchQuery.value,
        options: options.value,
        role: roleFilter.value,
      })
      .then(response => {
        const { filteredData, total, userTotal } = response.data

        userListTable.value = filteredData
        totalUserListTable.value = total
        userTotalLocal.value = userTotal

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
        loading.value = false
      })
  }

  // fetch data
  const deleteUser = id => {
    store
      .dispatch('user/deleteUser', { id })
      .then(response => {
        loading.value = true
        selectedRows.value = []
        fetchUsers()
        snackbarPlugin.showMessage({ content: 'Eliminado Correctamente', color: 'success', timeout: 10000 })
      })
      .catch(e => {
        snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
      })
  }

  // fetch data
  const deleteMany = () => {
    let users = selectedRows.value.map(user => user._id)
    store
      .dispatch('user/deleteMany', users)
      .then(response => {
        loading.value = true
        selectedRows.value = []
        fetchUsers()
        snackbarPlugin.showMessage({ content: 'Eliminado Correctamente', color: 'success', timeout: 10000 })
      })
      .catch(e => {
        snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
      })
  }

  const exportUsers = async () => {
    let data = userListTable.value.map(e => ({
      Documento: e.document,
      Nombre: e.name,
      Apellido: e.lastname,
      Email: e.email || '',
      'Fecha de Nacimiento': e.birthday || '',
      Direccion: e.address || '',
      City: e.city || '',
      Telefono: e.phone || '',
    }))

    exportData(data, 'usuarios.csv')
  }

  const uploadBatch = async stds => {
    if (stds.length > 0) {
      let r = await store.dispatch(
        'user/addBatchUsers',
        stds.map(s => {
          s._id = new ObjectID()
          s.password = s.document
          s.role = 'student'
          return s
        }),
      )
      if (r.status == 200) {
        loading.value = true
        fetchUsers()
        return snackbarPlugin.showMessage({ content: 'Subido Correctamente', color: 'success', timeout: 10000 })
      } else return snackbarPlugin.showMessage({ content: r.data.message, color: 'error', timeout: 10000 })
    }
  }

  watch([searchQuery, roleFilter, options], () => {
    if (timer.value) {
      clearTimeout(timer.value)
      timer.value = null
    }
    timer.value = setTimeout(() => {
      loading.value = true
      selectedRows.value = []
      fetchUsers()
    }, 800)
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'teacher') return 'warning'
    if (role === 'companySupervisor') return 'success'
    if (role === 'student') return 'info'
    if (role === 'admin') return 'error'
    if (role === 'academicAdvisor') return 'accent'

    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'teacher') return mdiAccountTieOutline
    if (role === 'student') return mdiSchoolOutline
    if (role === 'companySupervisor') return mdiDomain
    if (role === 'admin') return mdiDnsOutline
    if (role === 'academicAdvisor') return mdiAccountTie

    return mdiAccountOutline
  }

  const resolveUserTotalIcon = total => {
    if (total === 'Usuarios Totales') return { icon: mdiAccountOutline, color: 'primary' }
    else if (total === 'Alumnos') return { icon: mdiSchoolOutline, color: 'info' }
    else if (total === 'Docentes') return { icon: mdiAccountTieOutline, color: 'warning' }
    else if (total === 'Administradores') return { icon: mdiDnsOutline, color: 'error' }
    else if (total === 'Supervisores') return { icon: mdiDomain, color: 'success' }
    else if (total === 'Asesores Académicos') return { icon: mdiAccountTie, color: 'accent' }
    return { icon: mdiAccountOutline, color: 'primary' }
  }

  const resolveUserRoleTitle = role => {
    if (role) return store.state.roleOptions.filter(el => el.value == role)[0].title
  }

  const importColumns = ref([
    {
      value: 'document',
      text: 'Documento',
      replace: function (e) {
        return String(e).replace(/\D/g, '')
      },
      required: true,
    },
    {
      value: 'name',
      text: 'Nombre',
      replace: function (e) {
        //Remplazo por la primera letra en mayúscula y las demás en minúscula
        return capitalize(String(e))
      },
      validation: function (e) {
        return (
          //eslint-disable-next-line
          (e && String(e).trim() !== '' && /^[A-zÀ-ú_ ]*$/.test(String(e))) || `Nombre inválido`
        )
      },
      required: true,
    },
    {
      value: 'lastname',
      text: 'Apellido',
      replace: function (e) {
        //Remplazo por la primera letra en mayúscula y las demás en minúscula
        return capitalize(String(e))
      },
      validation: function (e) {
        return (
          //eslint-disable-next-line
          (e && String(e).trim() !== '' && /^[A-zÀ-ú_ ]*$$/.test(String(e))) || `Apellido inválido`
        )
      },
      required: true,
    },
    {
      value: 'phone',
      text: 'Teléfono',
      validation: function (e) {
        return true
      },
    },
    {
      value: 'address',
      text: 'Dirección',
      replace: function (e) {
        //Remplazo por la primera letra en mayúscula y las demás en minúscula
        return capitalize(String(e))
      },
    },
    {
      value: 'city',
      text: 'Ciudad',
      replace: function (e) {
        //Remplazo por la primera letra en mayúscula y las demás en minúscula
        return capitalize(String(e))
      },
    },
    {
      value: 'email',
      text: 'Email',
      replace: function (e) {
        //Remplazo por la primera letra en mayúscula y las demás en minúscula
        return String(e).toLowerCase()
      },
      validation: function (e) {
        if (e && String(e).length)
          return (
            //eslint-disable-next-line
            /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(e) || `Formato de email inválido`
          )
        else return true
      },
    },
  ])

  const capitalize = str => str.toLowerCase().replace(/\b([a-zÁ-ú]{3,})/g, w => w.charAt(0).toUpperCase() + w.slice(1))

  return {
    deleteMany,
    userListTable,
    tableColumns,
    searchQuery,
    roleFilter,
    totalUserListTable,
    loading,
    options,
    userTotalLocal,
    selectedRows,
    timer,
    exportUsers,
    fetchUsers,
    deleteUser,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserTotalIcon,
    resolveUserRoleTitle,
    importColumns,
    uploadBatch,
  }
}
