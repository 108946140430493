<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-badge class="customBadge" offset-x="40" offset-y="40" color="primary" bordered bottom overlap>
            <template #badge>
              <v-btn icon small style="z-index: 1" :loading="loadingUpload" @click="$refs.filePicker.click()">
                <v-icon color="white">{{ icons.mdiUpload }}</v-icon>
              </v-btn>
            </template>

            <v-avatar
              :color="userData.avatar ? '' : 'primary'"
              :class="userData.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="150"
              class=""
            >
              <v-img
                v-if="userData.avatar"
                :src="$store.state.apidomain + '/user/avatars/' + userData.avatar + '?rand=' + rand"
              ></v-img>
              <span v-else class="font-weight-semibold text-5xl">{{
                avatarText(userData.name + ' ' + userData.lastname)
              }}</span>
            </v-avatar>
          </v-badge>

          <!-- Add image -->
          <input
            type="file"
            ref="filePicker"
            accept="image/png, image/jpg, image/gif, image/jpeg"
            style="display: none"
            @change="uploadAvatar($event.target.files)"
          />

          <span class="mt-2 mb-2">{{ userData.name }} {{ userData.lastname }}</span>

          <v-chip
            label
            small
            :color="resolveUserRoleVariant(userData.role)"
            :class="`v-chip-light-bg text-sm font-weight-semibold ${resolveUserRoleVariant(
              userData.role,
            )}--text text-capitalize`"
          >
            {{ resolveUserRoleTitle(userData.role) }}
          </v-chip>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">Detalle</h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Nombre:</span>
              <span class="text--secondary">{{ userData.name }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Apellido:</span>
              <span class="text--secondary">{{ userData.lastname }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Documento:</span>
              <span class="text--secondary">{{ userData.document }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Email:</span>
              <span class="text--secondary">{{ userData.email }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Teléfono:</span>
              <span class="text--secondary">{{ userData.phone }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Dirección:</span>
              <span class="text--secondary">{{ userData.address }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Ciudad:</span>
              <span class="text--secondary">{{ userData.city }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Fecha de Nacimiento:</span>
              <span class="text--secondary">{{
                userData.birthday ? date(userData.birthday).format('DD/MM/YYYY') : '-'
              }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Rol:</span>
              <span class="text--secondary text-capitalize">{{ resolveUserRoleTitle(userData.role) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2" v-show="userData.companyData">
              <span class="font-weight-medium me-2">Empresa:</span>
              <span class="text--secondary text-capitalize">{{ userData.companyData.name }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn color="primary" class="me-3" @click="isBioDialogOpen = !isBioDialogOpen"> Modificar </v-btn>
        </v-card-actions>
      </v-card>

      <!-- edit profile dialog data -->
      <user-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :user-data="userData"
        @toggle-user="val => $emit('toggle-user', val)"
      ></user-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import { mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle, mdiUpload } from '@mdi/js'
import { inject, ref, nextTick } from 'vue'
import useUsersList from '../../user-list/useUsersList'
import UserBioEdit from './UserBioEdit.vue'
import snackbarPlugin from '@/plugins/snackbar'
import router from '@/router'

export default {
  components: {
    UserBioEdit,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant, resolveUserRoleTitle } = useUsersList()
    const date = inject('date')

    const isBioDialogOpen = ref(false)

    const rand = ref(Date.now())
    const filePicker = ref(null)
    const loadingUpload = ref(false)

    const uploadAvatar = async file => {
      if (!file.length) return false
      if (!file[0].type.includes('image/')) {
        snackbarPlugin.showMessage({ content: 'Formato de imagen incorrecto', color: 'error', timeout: 10000 })
        return false
      }

      loadingUpload.value = true

      let formdata = new FormData()
      formdata.append('userId', props.userData._id)
      formdata.append('avatar', file[0])

      let r = await store.dispatch('user/uploadAvatar', formdata)

      if (r.status == 200) {
        props.userData.avatar = ''
        nextTick(() => {
          props.userData.avatar = r.data.avatar
          rand.value = Date.now()
          filePicker.value = null
          loadingUpload.value = false
        })

        //Reload avatar login user
        if (!router.currentRoute.params.id) {
          store.commit('user/setAvatar', r.data.avatar)
          router.go()
        }
      } else snackbarPlugin.showMessage({ content: r.data.message, color: 'error', timeout: 10000 })
    }

    return {
      filePicker,
      loadingUpload,
      rand,
      date,
      resolveUserRoleVariant,
      avatarText,
      resolveUserRoleTitle,
      uploadAvatar,

      isBioDialogOpen,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiUpload,
      },
    }
  },
}
</script>

<style lang="scss">
.customBadge .v-badge__badge {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 0px;
}
</style>
