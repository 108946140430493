<template>
  <v-card class="mb-7" v-show="$props.selectedGroup.name">
    <v-card-title>
      {{ $props.title }}
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :class="{ 'mt-2': $vuetify.breakpoint.smAndDown, 'me-3': $vuetify.breakpoint.mdAndUp }"
        @click="openScholarship"
        >Emitir Escolaridad</v-btn
      >
      <v-btn
        color="primary"
        :class="{ 'mt-2 ml-2': $vuetify.breakpoint.smAndDown, 'me-3': $vuetify.breakpoint.mdAndUp }"
        @click="openCertificate"
        v-if="approved"
        >Certificado</v-btn
      >
    </v-card-title>
    <v-data-table
      :headers="tableSubjectsHeaders"
      :items="subjectsList"
      :options="{ itemsPerPage: 5 }"
      :items-per-page="5"
      :footer-props="{
        'items-per-page-text': 'Materias por página: ',
        'items-per-page-options': [5, 10, 15],
      }"
      class="table-cursor"
      :item-class="item => (selectedSubject == item ? 'active' : '')"
      @click:row="selectSubject"
    >
      <template v-slot:item.teacher="{ item }">
        <span>{{ item.teacher.name }} {{ item.teacher.lastname }}</span>
      </template>
      <template v-slot:item.assists="{ item }">
        <span
          >{{ getSubjectAttendances(item) }}/{{ item.classDays.length }}
          <v-icon small class="me-2" @click.stop="showAttendance(item)">
            {{ icons.mdiEye }}
          </v-icon></span
        >
      </template>
      <template v-slot:item.qualifications="{ item }">
        <span>{{ getQualification(item) }}</span>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} de {{ itemsLength }}
      </template>
      <template v-slot:no-data> No hay materias para mostrar </template>
    </v-data-table>

    <v-dialog v-model="dialogs.scholarship" max-width="800px" scrollable>
      <scholarship
        :groupData="$props.selectedGroup"
        :studentData="$props.studentData"
        :scholarshipData="scholarshipData"
        :isAdmin="$props.isAdmin"
        @close="dialogs.scholarship = false"
      ></scholarship>
    </v-dialog>

    <v-dialog v-model="dialogs.certificate" max-width="800px" scrollable>
      <certificate :certificateData="certificateData" @close="dialogs.certificate = false"></certificate>
    </v-dialog>

    <v-dialog v-model="dialogs.attendance" max-width="600px">
      <view-attendance :subject="selectedSubject" :group="$props.selectedGroup"></view-attendance>
    </v-dialog>

    <v-dialog v-model="dialogs.loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pt-3">
          Aguarde un momento..
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import snackbarPlugin from '@/plugins/snackbar'
import store from '@/store'
import { mdiDotsVertical, mdiDownload, mdiEye, mdiFilePdf } from '@mdi/js'
import { inject, ref, watch } from 'vue'
import Certificate from './Certificate'
import Scholarship from './Scholarship'
import ViewAttendance from './ViewAttendance'

export default {
  components: {
    Certificate,
    Scholarship,
    ViewAttendance,
  },
  props: {
    selectedGroup: {
      type: Object,
    },
    studentData: {
      type: Object,
    },
    title: {
      type: String,
    },
  },
  setup(props) {
    const selectedSubject = ref({})
    const scholarshipData = ref({})
    const subjectsList = ref(props.selectedGroup.subjects)
    const approved = ref(false)
    const certificateData = ref({})

    const date = inject('date')

    const dialogs = ref({
      attendance: false,
      scholarship: false,
      certificate: false,
      loading: false,
    })

    const tableSubjectsHeaders = [
      {
        text: 'Materia',
        value: 'name',
        sortable: false,
      },
      { text: 'Cargar Horaria', value: 'workLoad', sortable: false },
      { text: 'Docente', value: 'teacher', sortable: false },
      { text: 'Asistencias', value: 'assists', sortable: false },
      { text: 'Calificación', value: 'qualifications', sortable: false },
    ]

    const selectSubject = subject => {
      if (selectedSubject.value.name == subject.name) {
        selectedSubject.value = {}
      } else {
        selectedSubject.value = subject
      }
    }

    const checkApproved = () => {
      approved.value = false
      if (props.selectedGroup.students && props.selectedGroup.students.length) {
        if (props.selectedGroup.students.at(0).qualifications.length >= subjectsList.value.length)
          approved.value = props.selectedGroup.students.at(0).qualifications.every(q => {
            let subject = subjectsList.value.find(s => s._id == q.subject)
            if (subject) {
              if (subject.qualificationType == 'course') {
                return q.qualification >= 70
              } else {
                return q.qualification == 1
              }
            } else return true
          })
      } else return false
    }

    const showAttendance = subject => {
      selectedSubject.value = subject
      dialogs.value.attendance = true
    }

    const getSubjectAttendances = subject => {
      let sum = 0
      subject.classDays.forEach(c => {
        let day = props.selectedGroup.students.at(0).attendances.filter(a => a.classDay == c._id)
        if (day.length && day.at(0).status) sum++
      })
      return sum
    }

    const getQualification = subject => {
      let qualy = props.selectedGroup.students.at(0).qualifications.find(q => q.subject == subject._id)
      return qualy && qualy.qualification > -1
        ? subject.qualificationType == 'course'
          ? qualy.qualification
          : qualy.qualification == 1
          ? 'Aprobado'
          : 'No Aprobado'
        : ''
    }

    const openScholarship = () => {
      dialogs.value.loading = true

      store
        .dispatch('app-group/generateScholarship', { student: props.studentData, group: props.selectedGroup })
        .then(r => {
          scholarshipData.value = r.data.scholarship
          dialogs.value.scholarship = !dialogs.value.scholarship
          dialogs.value.loading = false
        })
        .catch(e => {
          dialogs.value.loading = false
          snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
        })
    }

    const issueDate = () => {
      return props.selectedGroup.students[0].qualifications
        .sort(function (a, b) {
          return new Date(b.date) - new Date(a.date)
        })
        .at(0).date
    }

    const openCertificate = () => {
      dialogs.value.loading = true

      store
        .dispatch('app-group/generateCertificate', { student: props.studentData._id, group: props.selectedGroup._id })
        .then(r => {
          certificateData.value = r.data.certificate
          const IssueDate = issueDate()
          certificateData.value.linkedin = encodeURI(
            `https://www.linkedin.com/profile/add?startTask=${certificateData.value._id}&name=${
              props.selectedGroup.courseName
            }&organizationId=2334233&issueYear=${date(IssueDate).format('YYYY')}&issueMonth=${date(IssueDate).format(
              'MM',
            )}&certUrl=https://gestion.biosempresarial.uy/verificar&certId=${certificateData.value._id}`,
          )
          dialogs.value.certificate = !dialogs.value.certificate
          dialogs.value.loading = false
        })
        .catch(e => {
          dialogs.value.loading = false
          snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
        })
    }

    checkApproved()

    watch(
      () => props.studentData,
      value => {
        subjectsList.value = props.selectedGroup.subjects
        checkApproved()
      },
    )

    watch(
      () => props.selectedGroup,
      value => {
        subjectsList.value = props.selectedGroup.subjects
        checkApproved()
      },
    )

    return {
      certificateData,
      openCertificate,
      approved,
      subjectsList,
      dialogs,
      showAttendance,
      openScholarship,
      tableSubjectsHeaders,
      getSubjectAttendances,
      selectSubject,
      getQualification,
      selectedSubject,
      scholarshipData,
      icons: {
        mdiDotsVertical,
        mdiFilePdf,
        mdiEye,
        mdiDownload,
      },
    }
  },
}
</script>
