<template>
  <!-- edit profile dialog -->
  <v-dialog v-model="isBioDialogOpen" max-width="650px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5"> Editar información </v-card-title>

      <v-card-text class="mt-5">
        <v-form v-model="valid" ref="form" class="multi-col-validation" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="userDataLocal.name"
                :rules="[validators.required]"
                outlined
                dense
                label="Nombre"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="userDataLocal.lastname"
                :rules="[validators.required]"
                outlined
                dense
                label="Apellido"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="userDataLocal.document"
                :disabled="!$can('update', 'profile', 'document')"
                :rules="[validators.required]"
                outlined
                dense
                label="Documento"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="$can('update', 'profile', 'password')">
              <v-text-field
                v-model="userDataLocal.password"
                outlined
                dense
                label="Contraseña"
                :type="isPasswordVisible ? 'text' : 'password'"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                hide-details="auto"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="userDataLocal.email"
                :rules="
                  $can('update', 'user')
                    ? [validators.emailValidator]
                    : [validators.required, validators.emailValidator]
                "
                outlined
                dense
                type="email"
                label="Email"
                placeholder="Email"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="userDataLocal.phone"
                :rules="$can('update', 'user') ? [] : [validators.required]"
                outlined
                dense
                label="Telefono"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="userDataLocal.address"
                :rules="$can('update', 'user') ? [] : [validators.required]"
                outlined
                dense
                label="Direccion"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="userDataLocal.city"
                :rules="$can('update', 'user') ? [] : [validators.required]"
                outlined
                dense
                label="Ciudad"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <datepicker-birthday-picker
                :rules="$can('update', 'user') ? [] : [validators.required]"
                v-model="userDataLocal.birthday"
              ></datepicker-birthday-picker>
            </v-col>
            <v-col cols="12" sm="6" v-if="$can('update', 'profile', 'role')">
              <v-select
                v-model="userDataLocal.role"
                :rules="[validators.required]"
                label="Rol"
                :items="$store.state.roleOptions"
                item-text="title"
                item-value="value"
                outlined
                dense
                hide-details="auto"
                class="mb-6"
              >
              </v-select>
            </v-col>

            <v-col cols="12" sm="6" v-if="$can('update', 'profile', 'company')">
              <companies-selection v-model="userDataLocal.company" class="mb-6"></companies-selection>
            </v-col>

            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn color="primary" class="me-3" type="submit"> Guardar </v-btn>

              <v-btn outlined color="secondary" @click.prevent="$emit('update:is-bio-dialog-open', false)">
                Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import snackbarPlugin from '@/plugins/snackbar'
import { emailValidator, required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref, watch } from 'vue'
import datepickerBirthdayPicker from './DatepickerBirthdayPicker.vue'
import CompaniesSelection from '@/views/components/CompaniesSelection.vue'
import router from '@/router'

import store from '@/store'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  components: {
    datepickerBirthdayPicker,
    CompaniesSelection,
  },
  setup(props, { emit }) {
    const userDataLocal = ref({})
    const valid = ref(false)
    const form = ref(null)
    const isPasswordVisible = ref(false)
    const userDataStorage = store.getters['user/getUser']

    userDataLocal.value = JSON.parse(JSON.stringify(props.userData))

    const validate = () => {
      form.value.validate()
    }

    // on form submit
    const onSubmit = () => {
      if (valid.value) {
        store
          .dispatch('user/updateUser', { user: userDataLocal.value, userBkp: props.userData })
          .then(r => {
            emit('update:is-bio-dialog-open', false)
            emit('toggle-user')
            snackbarPlugin.showMessage({ content: 'Guardado Correctamente', color: 'success', timeout: 10000 })
          })
          .catch(e => {
            snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
          })
      } else {
        validate()
      }
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        userDataLocal.value = JSON.parse(JSON.stringify(props.userData))
      },
    )

    return {
      userDataLocal,
      onSubmit,
      valid,
      form,
      validate,
      isPasswordVisible,
      userDataStorage,
      // validation
      validators: { required, emailValidator },
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>
