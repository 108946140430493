<template>
  <v-card>
    <v-card-title>
      <span class="headline">Detalle de Asistencias - {{ subject.name }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="subject.classDays"
              :search="search"
              :footer-props="{
                'items-per-page-text': 'Clases por página: ',
                'items-per-page-options': [15, 30, 50, 100],
              }"
            >
              <template #[`item.fecha`]="{ item }">
                <span>{{ date(item.start).format('DD/MM/YYYY HH:mm') }}</span>
              </template>
              <template #[`item.asistencia`]="{ item }">
                <v-icon v-if="getAttendaceStatus(item._id) == true" color="success">
                  {{ icons.mdiCheckCircle }}
                </v-icon>
                <v-icon v-else-if="getAttendaceStatus(item._id) == false" color="error">
                  {{ icons.mdiCloseCircle }}
                </v-icon>
                <v-icon v-else> {{ icons.mdiCircle }} </v-icon>
              </template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} de {{ itemsLength }}
              </template>
              <template v-slot:no-data> No hay clases para mostrar </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCheckCircle, mdiCircle, mdiCloseCircle } from '@mdi/js'
import { inject, ref } from 'vue'

export default {
  components: {},
  props: {
    subject: {
      type: Object,
    },
    group: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const date = inject('date')
    const search = ref('')

    const headers = [
      { text: 'Fecha', value: 'fecha' },
      { text: 'Asistencia', value: 'asistencia' },
    ]

    const getAttendaceStatus = item => {
      let attendance = props.group.students[0].attendances.find(a => a.classDay == item)
      return attendance ? attendance.status : attendance
    }

    return {
      date,
      headers,
      search,
      getAttendaceStatus,

      //icons
      icons: {
        mdiCheckCircle,
        mdiCloseCircle,
        mdiCircle,
      },
    }
  },
}
</script>
